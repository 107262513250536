// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-dates/initialize';
import { Helmet } from 'react-helmet';

// components
import SVG from 'react-inlinesvg';
import MonthSelector from '../../components/selector/MonthSelector';

import AlertModal from 'app/components/modals/AlertModal';

// context
import BillingContext from 'app/components/context/BillingContext';

// styles
import './Invoices.scss';
import 'react-dates/lib/css/_datepicker.css';

// custom hooks
import useAllTenantsRequest from 'app/components/hooks/useAllTenantsRequest';
import useAllInvoicesRequest from '../../components/hooks/useAllInvoicesRequest';

// utils
import { toAbsoluteUrl } from '_metronic/_helpers';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';
import moment from 'moment';

import YearSelector from 'app/components/selector/YearSelector';
import { exportInvoice, sendAllInvoices, sendInvoice } from './helpers';

const InvoicesPage = () => {
  const initialData = useRef([]);
  const [tenantsData, setTenantsData] = useState([]);
  const { invoiceDate, setInvoiceDate } = useContext(BillingContext);
  const [search, setSearch] = useState('');
  const [month, setMonth] = useState(moment(invoiceDate).format('YYYY-MM'));
  const [year, setYear] = useState(moment(invoiceDate).format('YYYY'));
  const [invoicesData, setInvoicesData] = useState([]);
  const [sale, setSale] = useState([]);
  const [checkedTenants, setCheckedTenants] = useState([]);
  const [alert, setAlert] = useState('');

  // custom hooks
  const { tenants, tenantsIsLoading } = useAllTenantsRequest();
  const { invoices, invoicesIsLoading } = useAllInvoicesRequest(month);

  useEffect(() => {
    if (tenants && !tenantsIsLoading) {
      const filtered = tenants.filter(
        item =>
          !item.deactivation_date &&
          item?.id !== 155 &&
          item?.id !== 164 &&
          item?.id !== 182
      );
      const sorted = filtered.sort((a, b) =>
        a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
      );
      setTenantsData(sorted);
      initialData.current = sorted;
    }
  }, [tenants, tenantsIsLoading]);

  useEffect(() => {
    const getInvoices = () => {
      if (invoices && !invoicesIsLoading) {
        setInvoicesData(invoices.tenants.filter(item => item.id !== null));
        setInvoiceDate(month);
      }
    };
    getInvoices();
  }, [invoices, invoicesIsLoading]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredItems = tenants.filter(item => {
        let matches = false;
        for (let key in item) {
          if (
            String(item[key])
              .toLowerCase()
              .indexOf(search) > -1
          ) {
            matches = true;
            break;
          }
        }
        return matches;
      });
      setTenantsData(filteredItems.filter(item => item.id !== 155));
    } else {
      setTenantsData(initialData.current);
    }
  }, [search]);

  const checkInput = () => {
    if (checkedTenants.length === tenants.length) {
      setCheckedTenants([]);
    } else {
      const allTenantIds = tenants.map(item => item.id);
      setCheckedTenants(allTenantIds);
    }
  };

  const totalSale = (tenantId, value) => {
    let key = `${tenantId}`;
    setSale({ ...sale, ...{ [key]: value } });
  };

  return (
    <div className="card margin-top-25 invoicesContainer card-custom">
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      {alert && <AlertModal alert={alert} setAlert={setAlert} />}
      <>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="tabletitle">
              <FormattedMessage id="INVOICES" />
            </span>
          </h3>
        </div>
        <div className="card-body py-0">
          <div className="w-full form-inline">
            <input
              value={search}
              onChange={e => setSearch(e.target.value)}
              className="form-control invoices-search mr-sm-2"
              type="text"
              placeholder="Search"
            />
            <div className="search-month-send">
              <YearSelector
                onChange={e => {
                  setYear(e.target.value);
                }}
                value={year}
              />
              <MonthSelector
                onChange={e => {
                  setMonth(e.target.value);
                }}
                value={month}
                year={year}
              />
              <div className="send-inv-svg">
                <SVG
                  onClick={() =>
                    sendAllInvoices({
                      month,
                      year,
                      checkedTenants,
                      setAlert,
                      sale
                    })
                  }
                  src={toAbsoluteUrl('/media/svg/sendinvoices.svg')}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center invoices-table"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr>
                  <th className="width-25">
                    <input
                      onChange={checkInput}
                      className="invoice-checkbox"
                      type="checkbox"
                      checked={checkedTenants.length === tenants.length}
                    />
                  </th>
                  <th className="width-158 invoice-th">
                    <p className="text-center">
                      <FormattedMessage id="TENANTS" />
                    </p>
                  </th>
                  <th className="width-100 invoice-th">
                    <p className="text-center">COOLING GJ</p>
                  </th>
                  <th className="width-100 invoice-th">
                    <p className="text-center">HEATING GJ</p>
                  </th>
                  <th className="width-100 invoice-th">
                    <p className="text-center">COOLING COST</p>
                  </th>
                  <th className="width-100 invoice-th">
                    <p className="text-center">HEATING COST</p>
                  </th>
                  <th className="width-100 invoice-th">
                    <p className="text-center">FIXED COST</p>
                  </th>
                  <th className="width-100 invoice-th">
                    <p className="text-center">TOTAL COST</p>
                  </th>
                  <th className="width-50 invoice-th">
                    <p className="text-center">
                      <FormattedMessage id="TARIFF" />
                    </p>
                  </th>
                  <th className="width-50 invoice-th">
                    <p className="text-center">
                      <FormattedMessage id="SALE" />
                    </p>
                  </th>
                  <th className="width-130 invoice-th">
                    <p className="text-center">
                      <FormattedMessage id="DOWNLOAD.INVOICE" />
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tenantsData &&
                  tenantsData?.length > 0 &&
                  tenantsData.map(item => {
                    const checkMonth = month === moment().format('YYYY-MM');

                    const totalHeatingGjh =
                      !checkMonth &&
                      invoicesData
                        .filter(invoice => invoice.id === item.id)
                        ?.map(gjh =>
                          gjh?.heatingInvoice?.totalEnergyInMonth.toFixed(2)
                        );

                    const totalHeatingCost =
                      !checkMonth &&
                      invoicesData
                        .filter(invoice => invoice.id === item.id)
                        ?.map(gjh => gjh?.heatingInvoice?.finalCost.toFixed(2));

                    const totalCoolingGjh =
                      !checkMonth &&
                      invoicesData
                        .filter(invoice => invoice.id === item.id)
                        ?.map(gjh =>
                          gjh?.coolingInvoice?.totalEnergyInMonth.toFixed(2)
                        );

                    const totalCoolingCost =
                      !checkMonth &&
                      invoicesData
                        .filter(invoice => invoice.id === item.id)
                        ?.map(gjh => gjh?.coolingInvoice?.finalCost.toFixed(2));

                    const totalCost =
                      !checkMonth &&
                      (
                        parseFloat(totalHeatingCost) +
                        parseFloat(totalCoolingCost) +
                        item.tariff_heat.fixed
                      )?.toFixed(2);

                    return (
                      <tr key={item.id}>
                        <td>
                          <label className="checkbox-container">
                            <input
                              type="checkbox"
                              className="invoice-checkbox"
                              checked={checkedTenants.includes(item.id)}
                              onChange={() => {
                                if (checkedTenants.includes(item.id)) {
                                  setCheckedTenants(
                                    checkedTenants.filter(id => id !== item.id)
                                  );
                                } else {
                                  setCheckedTenants([
                                    ...checkedTenants,
                                    item.id
                                  ]);
                                }
                              }}
                            />
                          </label>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {item.name}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder text-center d-block font-size-lg">
                            {totalCoolingGjh > 0
                              ? numbersWithSpaces(totalCoolingGjh)
                              : 0}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder text-center d-block font-size-lg">
                            {totalHeatingGjh > 0
                              ? numbersWithSpaces(totalHeatingGjh)
                              : 0}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder text-center d-block font-size-lg">
                            {totalCoolingCost > 0 // && item?.id !== 150
                              ? numbersWithSpaces(totalCoolingCost) + '₾'
                              : 0}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder text-center d-block font-size-lg">
                            {totalHeatingCost > 0 // && item?.id !== 150
                              ? numbersWithSpaces(totalHeatingCost) + '₾'
                              : 0}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder text-center d-block font-size-lg">
                            {item.tariff_heat.fixed > 0
                              ? numbersWithSpaces(item.tariff_heat.fixed) + '₾'
                              : 0}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder text-center d-block font-size-lg">
                            {totalCost > 0 // && item?.id !== 150
                              ? numbersWithSpaces(totalCost) + '₾'
                              : 0}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {item.tariff_cold.name ? (
                              <>
                                <span>Tariff </span>
                                {item.tariff_cold.name}
                              </>
                            ) : (
                              <span>-</span>
                            )}
                          </span>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="invoice-input"
                            onChange={e => {
                              totalSale(item.id, Number(e.target.value));
                            }}
                          />
                        </td>
                        <td>
                          <div className="downl-invc">
                            <span className="symbol-label cursor-pointer">
                              <SVG
                                src={toAbsoluteUrl('/media/svg/download.svg')}
                                className="h-75 align-self-end"
                                onClick={() =>
                                  exportInvoice({
                                    tId: item.id,
                                    invoicesData,
                                    setAlert,
                                    month,
                                    sale
                                  })
                                }
                              />
                            </span>
                            <span className="symbol-label cursor-pointer">
                              <SVG
                                src={toAbsoluteUrl('/media/svg/share.svg')}
                                className="h-75 align-self-end cur"
                                onClick={() =>
                                  sendInvoice({
                                    tId: item.id,
                                    month,
                                    year,
                                    sale,
                                    setAlert
                                  })
                                }
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default InvoicesPage;
